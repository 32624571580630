import { useState } from 'react'
import { useRouter } from 'next/router'
import { ErrorProps } from 'next/error'
import Link from 'next/link'
import {
  Box,
  Button,
  colors,
  Container,
  Image,
  Stack,
  Text,
  theme,
  useMediaQuery
} from '@gositeinc/ui'

import { IErrorPage } from '../types'
import { useButton } from '../utils'
// import { useState } from 'react'

export const ErrorPage = ({ data, displayError = false, unhandledError }: ErrorPageProps): JSX.Element => {
  const router = useRouter()
  const { getVariant } = useButton()
  const [hasError, setError] = useState(false)
  const isLargerScreen = useMediaQuery(theme.breakpoints.up('lg'))

  const errorHeading = ['An error has occurred in this Component.']

  const onRedirect = (): void => {
    if (data?.redirectButton?.url == null) {
      router.push('/').catch(() => { })
    }
    router.push(data?.redirectButton?.url as string).catch(() => { })
  }

  const onReload = (): void => {
    router.reload()
  }

  const showErrorDetails = (): void => {
    setError(!hasError)
  }

  // if (data == null) {
  //   router.push('/').catch(() => { })
  // }

  const isHandledError = (): JSX.Element => {
    return (
      <>
        <Text component='div' rich mb={4}>
          {data?.description}
        </Text>
        <Button
          variant={getVariant(data?.redirectButton?.variant)}
          onClick={onRedirect}
        >
          {data?.redirectButton?.text}
        </Button>
      </>
    )
  }

  const isUnhandledError = (): JSX.Element => {
    return (
      <>
        <Button
          size='large'
          variant={getVariant(data?.redirectButton?.variant)}
          onClick={onReload}
        >
          Reload
        </Button>
        <Button
          sx={{ marginLeft: 2 }}
          size='large'
          variant={getVariant(data?.redirectButton?.variant)}
          onClick={onRedirect}
        >
          {data?.redirectButton?.text}
        </Button>
        <Box
          sx={{ backgroundColor: colors.grey[100] }}
          mt={6}
          pt={3}
          pb={1}
        >
          <Container>
            <Box
              textAlign='center'
            >
              <Button
                onClick={showErrorDetails}
                sx={{
                  color: colors.red.A700,
                  fontSize: 18,
                  mb: 20
                }}
              >
                {hasError ? 'Hide' : 'Show'} Full Details
              </Button>
              {hasError && (
                <Text
                  pb={4}
                  textAlign='left'
                >
                  <code>{unhandledError}</code>
                </Text>)}
            </Box>
          </Container>
        </Box>
      </>
    )
  }

  return (
    <Box
      textAlign='center'
    >
      <Container>
        <Stack
          direction='row'
          justifyContent='space-between'
          py={2}
          height={isLargerScreen ? 80 : 60}
        >
          <Link
            href='/'
          >
            <Image
              src='/images/logo.svg'
              width={isLargerScreen ? 138 : 100}
              height={isLargerScreen ? 44 : 32}
              style={{
                objectFit: 'contain'
              }}
            />
          </Link>
        </Stack>
      </Container>
      <Box
        sx={{ backgroundColor: colors.grey[100] }}
        mb={6}
        py={10}
      >
        <Text rich>
          {displayError ? errorHeading : data?.title}
        </Text>
      </Box>
      <Container>
        {displayError ? isUnhandledError() : isHandledError()}
      </Container>
    </Box>
  )
}

export type ErrorPageProps = {
  data: IErrorPage | null
  displayError?: boolean
  unhandledError?: any
} & ErrorProps
