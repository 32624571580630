import {
  GetServerSideProps,
  GetServerSidePropsContext
} from 'next'

import {
  Page,
  PageProps
} from '../src/domain'
import {
  getPageProps
} from '../src/utils'

// The file is called [[...slug]].js because we're using Next's
// optional catch all routes feature. See the related docs:
// https://nextjs.org/docs/routing/dynamic-routes#optional-catch-all-routes

const RootPage = (props: PageProps): JSX.Element => <Page {...props} />

export const getServerSideProps: GetServerSideProps = async (context: GetServerSidePropsContext) => {
  return await getPageProps(context)
}

export default RootPage
