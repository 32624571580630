import React from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import Link from 'next/link'
import {
  Alert,
  Box,
  Button,
  Footer,
  Navbar,
  NotificationBanner,
  useTheme,
  createTheme,
  ThemeOptions,
  ThemeProvider,
  IGlobal
} from '@gositeinc/ui'

import { IPageContext } from 'src/types'
import { Seo } from '../components'
import { getNavbarLinksInfusedWithSearchParams } from '../utils'
import { ANALYTICS_CONSTANTS } from '../analytics-constants'

const Layout = ({
  children,
  global,
  pageContext,
  preview = false
}: LayoutProps): JSX.Element => {
  const theme = useTheme()
  const overrideThemeObject: ThemeOptions = {}

  const {
    navbar,
    navbarLinks,
    footer,
    marketingPromotion
  } = global

  const {
    primaryColor,
    metadata,
    metaTitleSuffix,
    styles,
    navbarTheme,
    displayNavbarLinks,
    slug
  } = pageContext

  if (primaryColor !== null && primaryColor !== undefined && primaryColor !== '') {
    overrideThemeObject.palette = {
      primary: { main: primaryColor }
    }
  }

  // official way to implement a nested theme
  // https://mui.com/customization/theming/#createtheme-options-args-theme
  const pageTheme = createTheme(theme, overrideThemeObject)

  const renderNavbar = (): JSX.Element => {
    return (
      <Navbar
        data={navbar}
        navbarLinks={getNavbarLinksInfusedWithSearchParams(navbarLinks)}
        navbarTheme={navbarTheme}
        displayNavbarLinks={displayNavbarLinks}
        slug={slug}
        analytics={ANALYTICS_CONSTANTS.navbar}
      />
    )
  }

  return (
    <>
      <Head>
        <meta name='keywords' content={metadata?.keywords} />
      </Head>
      <Seo
        titleTemplate={`%s | ${metaTitleSuffix}`}
        metadata={{
          ...metadata,
          metaTitle: `${metadata?.metaTitle} | ${metaTitleSuffix}`
        }}
      />
      <ThemeProvider theme={pageTheme}>
        <Box sx={styles}>
          {/* Aligned to the top */}
          <>
            {/* Show a banner if preview mode is on */}
            {preview && <PreviewModeBanner />}
            {marketingPromotion != null && (
              <NotificationBanner
                storageKey='mkt_notification_banner_promotion'
                data={marketingPromotion}
              />
            )}

            {renderNavbar()}
            {children}
          </>
          {/* Aligned to the bottom */}
          <Footer data={footer} doesSiteHaveTermlyConsentBanner />
        </Box>
      </ThemeProvider>
    </>
  )
}

const PreviewModeBanner = (): JSX.Element => {
  const router = useRouter()
  // const exitURL = `/api/exitPreview?redirect=${encodeURIComponent(
  //   router.asPath
  // )}`
  return (
    <Alert
      severity='error'
      action={
        <Link
          href={`/api/exitPreview?redirect=${router.asPath}`}
        >
          <Button sx={{ height: '12px' }} color='inherit' size='small'>
            Turn Off
          </Button>
        </Link>
      }
    >
      Preview mode is on
    </Alert>
  )
}

interface LayoutProps {
  children: React.ReactNode
  global: IGlobal
  pageContext: IPageContext
  preview?: boolean
}

export default Layout
