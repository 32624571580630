import React from 'react'
import { useRouter } from 'next/router'
import {
  IMetadata,
  IGlobal
} from '@gositeinc/ui'

import Layout from './layout'
import Sections from './sections'
import { StickyMenu } from './sticky-menu'
import {
  IErrorPage,
  ISection,
  IPageContext
} from 'src/types'
import { ErrorPage } from './error-page'

export const Page = ({
  sections,
  preview,
  errorPage,
  global,
  pageContext,
  slugNotFound
}: PageProps): JSX.Element => {
  const router = useRouter()

  if (slugNotFound) {
    return (
      <ErrorPage
        data={errorPage}
        statusCode={404}
      />
    )
  }

  const renderContent = (): JSX.Element => {
    const { slug } = pageContext

    // if (slug.includes('promotional') || slug.includes('kitchen-sink')) return <></>

    return (
      <>
        <Sections sections={sections} />
        {slug === 'kitchen-sink' && (
          <StickyMenu sections={sections} />
        )}
      </>
    )
  }

  if (router.isFallback) {
    return <div className='container'>Loading...</div>
  }

  return (
    <Layout
      global={global}
      pageContext={pageContext}
      preview={preview}
    >
      {/* Display content sections */}
      {renderContent()}
    </Layout>
  )
}

export interface PageProps {
  errorPage: IErrorPage
  global: IGlobal
  metadata: IMetadata
  pageContext: IPageContext
  preview: boolean
  sections: ISection[]
  slugNotFound: boolean
}
