import React, { useEffect, useState } from 'react'
import capitalize from 'lodash/capitalize'
import { Link } from 'react-scroll'
import { FiMenu } from 'react-icons/fi'
import { IoClose } from 'react-icons/io5'
import { Box, IconButton, Card, Text, styled, useTheme } from '@gositeinc/ui'

import { ISection } from '../types'

const CONTAINER_ANIMATED_CLASSNAME = 'animate__animated animate__fadeIn animate__repeat-1'

export const StickyMenu = ({ sections }: StickyMenuProps): JSX.Element => {
  const theme = useTheme()
  const [isExpanded, setIsExpanded] = useState(false)
  const [containerClassName, setContainerClassName] = useState(CONTAINER_ANIMATED_CLASSNAME)

  function handleClick (): void {
    setIsExpanded(!isExpanded)
  }

  function getSectionName (value: string): string | undefined {
    return capitalize(value.split('.')[1].split('-').join(' '))
  }

  useEffect(() => {
    setContainerClassName(isExpanded ? CONTAINER_ANIMATED_CLASSNAME : '')
  }, [isExpanded])

  return (
    <Root>
      <MenuButton onClick={handleClick}>
        {isExpanded
          ? <IoClose fill={theme.palette.common.white} />
          : <FiMenu stroke={theme.palette.common.white} />}
      </MenuButton>
      <Menu>
        {isExpanded && (
          <Card elevation={24}>
            <Box className={containerClassName}>
              {sections.map(
                section => (
                  <Link
                    activeClass='active'
                    spy
                    offset={-90}
                    smooth
                    duration={500}
                    key={`${section.__component}-${section.id}`}
                    to={`${section.__component}-${section.id}`}
                    underline='hover'
                  >
                    <Box px={2} py={1}>
                      <Text variant='body1' color='primary' fontWeight={theme.typography.fontWeightMedium}>{getSectionName(section.__component)}
                      </Text>
                      <Text variant='caption' color='primary'>{section.metaTitle}
                      </Text>
                    </Box>
                  </Link>)
              )}
            </Box>
          </Card>
        )}
      </Menu>
    </Root>
  )
}

const Root = styled(Box)(() => ({
  '& a': {
    cursor: 'pointer !important'
  }
}))

const MenuButton = styled(IconButton)(({ theme }) => ({
  bottom: 40,
  right: '0',
  position: 'fixed',
  zIndex: 2,
  marginRight: '20px !important',
  background: '#ca3456',
  '&:hover': {
    background: '#ca3456'
  }
}))

const Menu = styled(Box)(({ theme }) => ({
  bottom: 90,
  right: '0',
  position: 'fixed',
  zIndex: 2,
  '& .MuiCard-root': {
    width: 400,
    height: 400,
    marginRight: 30,
    overflowY: 'scroll',
    display: 'flex',
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      margin: 0
    }
  }
}))

interface StickyMenuProps {
  sections: ISection[]
}
